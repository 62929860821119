export enum WorkOrderItemTypes {
  pick_up = 'pick_up',
  pickup_overdue = 'pickup_overdue',
  pick_up_construction_zone_signage = 'pick_up_construction_zone_signage',
  drop_off = 'drop_off',
  update = 'update',
  replace = 'replace',
  notification = 'notification',
  retrieve = 'retrieve',
}

export enum WorkOrderItemPriorities {
  NORMAL = 1,
  HIGH = 2,
}

export enum WorkorderItemStates {
  open = 'open',
  done = 'done',
}
