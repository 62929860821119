import { Checkbox, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { FunctionComponent, ReactNode } from 'react';
import { IWithClassName } from '../../../types';
import classNames from 'classnames';

const useStyles = makeStyles<Theme, IProps>({
  checkboxLabel: { paddingTop: '4px !important;', color: (props) => (props.disabled ? 'grey' : 'inherit') },
  checkboxClickableArea: {
    cursor: 'pointer',
  },
});

interface IProps extends IWithClassName {
  label: ReactNode | string;
  checked: boolean;
  disabled?: boolean;
  onChange?(checked: boolean): void;
  readOnly?: boolean;
}

export const CheckboxWithLabel: FunctionComponent<IProps> = (props) => {
  const { label, checked, className, disabled = false, onChange, readOnly = false } = props;
  const C = useStyles(props);
  return (
    <Grid
      role="button"
      container
      className={classNames(className, C.checkboxClickableArea)}
      justify="center"
      alignItems="center"
      onClick={() => onChange?.(!checked)}
    >
      <Grid item>
        <Checkbox
          onChange={(_event, checked) => onChange?.(checked)}
          checked={checked}
          disabled={disabled || readOnly}
        />
      </Grid>
      <Grid item className={C.checkboxLabel}>
        {label}
      </Grid>
    </Grid>
  );
};
