import { Grid, Tooltip } from '@material-ui/core';
import {
  ArrowDownwardRounded,
  ArrowUpwardRounded,
  Check,
  CompareArrowsRounded,
  CreateRounded,
  LocationSearchingRounded,
  NotificationsRounded,
  SaveOutlined,
} from '@material-ui/icons';
import I18n from 'i18n-js';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { WorkOrderItemTypes } from '../../planning/planning.constants';
import {
  setReorderingHoveredWorkOrderItem,
  setReorderingMarkerPopup,
  toggleCheckReorderingWorkOrder,
} from '../store/reordering.actions';
import { IReorderingWorkOrderItem } from '../types/reordering-work-order-item';
import { IDraggableProps } from '../../types';

export const ReorderingListItem: FC<IDraggableProps<IReorderingWorkOrderItem, {}>> = ({ item, listeners }) => {
  const dispatch = useDispatch();
  const woi = item;

  const toggleCheck = (event: React.MouseEvent): void => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(toggleCheckReorderingWorkOrder(item));
  };

  const openPopup = (): void => {
    dispatch(setReorderingMarkerPopup(item));
  };

  const onMouseEnter = () => dispatch(setReorderingHoveredWorkOrderItem(woi));
  const onMouseLeave = () => dispatch(setReorderingHoveredWorkOrderItem(null));

  const getWoiIcon = (item: IReorderingWorkOrderItem) => {
    switch (item.type) {
      case WorkOrderItemTypes.pick_up:
        return <ArrowUpwardRounded fontSize="small" style={{ position: 'relative', top: 4, right: 2 }} />;
      case WorkOrderItemTypes.drop_off:
        return <ArrowDownwardRounded fontSize="small" style={{ position: 'relative', top: 5, right: 2 }} />;
      case WorkOrderItemTypes.retrieve:
        return <LocationSearchingRounded fontSize="small" style={{ position: 'relative', top: 5, right: 2 }} />;
      case WorkOrderItemTypes.replace:
        return <CompareArrowsRounded fontSize="small" style={{ position: 'relative', top: 5, right: 2 }} />;
      case WorkOrderItemTypes.update:
        return <CreateRounded fontSize="small" style={{ position: 'relative', top: 5, right: 2 }} />;
      case WorkOrderItemTypes.notification:
        return <NotificationsRounded fontSize="small" style={{ position: 'relative', top: 4, right: 2 }} />;
    }
  };

  return (
    <Grid
      className="reordering-list-item"
      container={true}
      justify="space-between"
      alignItems="stretch"
      wrap="nowrap"
      onClick={openPopup}
      {...listeners}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Grid style={{ flex: 1, display: 'inline-flex' }} item={true}>
        <div className="reordering-list-sequence">{woi.sequence}</div>

        {woi.checked ? (
          <div className="reordering-list-checkbox reordering-list-checkbox-checked" onClick={toggleCheck}>
            <Tooltip title={I18n.t('Planning checked')}>
              <Check fontSize={'small'} style={{ transform: 'scale(0.8)', marginTop: 1 }} />
            </Tooltip>
          </div>
        ) : (
          <div className="reordering-list-checkbox" onClick={toggleCheck}>
            <Tooltip title={I18n.t('Planning not checked')}>
              <SaveOutlined fontSize={'small'} style={{ transform: 'scale(0.8)', marginTop: 1 }} />
            </Tooltip>
          </div>
        )}
      </Grid>

      <Grid className="reordering-list-address">{woi.address}</Grid>

      <Grid className="reordering-list-type">
        <div className="reordering-list-typeicon">{getWoiIcon(woi)}</div>
        <div>{woi.numberOfSigns}</div>
      </Grid>
    </Grid>
  );
};
