import { IWithClassName, IWithId } from './generic.types';
import { IGeometry } from './geojson.types';

export enum ConflictCategory {
  bench = 'bench',
  constructionZone = 'constructionZone',
  parkingBan = 'parkingBan',
  publicTransport = 'publicTransport',
  fireDepartment = 'fireDepartment',
  greenZone = 'greenZone',
  portZone = 'portZone',
  mobility = 'mobility',
  utilityCompanies = 'utilityCompanies',
  shippingPolice = 'shippingPolice',
  cityHarbour = 'cityHarbour',
  trafficPolice = 'trafficPolice',
  projectSites = 'projectSites',
  event = 'event',
  dedicatedParkingCarSharing = 'dedicatedParkingCarSharing',
  tunnel = 'tunnel',
  markets = 'markets',
  streetInfrastructure = 'streetInfrastructure',
  trafficSignaling = 'trafficSignaling',
  brabantsTunnel = 'brabantsTunnel',
  neighborhoodParking = 'neighborhoodParking',
  dedicatedParkingCambio = 'dedicatedParkingCambio',
  zoo = 'zoo',
  retailCatering = 'retailCatering',
  prison = 'prison',
  railwayInfrastructure = 'railwayInfrastructure',
  crane = 'crane',
  artWork = 'artWork',
  loadingZone = 'loadingZone',
  dedicatedParkingChargingStation = 'dedicatedParkingChargingStation',
  airport = 'airport',
  dedicatedParkingDisabledPerson = 'dedicatedParkingDisabledPerson',
  railway = 'railway',
  detour = 'detour',
  oosterweelZone = 'oosterweelZone',
  parkingMachine = 'parkingMachine',
  highway = 'highway',
  diamantwijk = 'diamantwijk',
  publicOrder = 'publicOrder',
  football = 'football',
  cityCleaning = 'cityCleaning',
  railroadPolice = 'railroadPolice',
  velo = 'velo',
  streetLightning = 'streetLightning',
  dedicatedParkingTaxi = 'dedicatedParkingTaxi',
  terrace = 'terrace',
  temporaryTrafficLights = 'temporaryTrafficLights',
  garbageCans = 'garbageCans',
}
export interface IConflictSvgIcon extends IWithClassName {
  variant: ConflictCategory;
}

export interface IConflict extends IWithId {
  sgwRequestId: number;
  sgwDrawingId: number;
  sgwPhaseId: number;
  geometry: IGeometry;
  dossierNumber: string;
}

export enum ConflictGroupState {
  awaitingFeedback = 'awaitingFeedback',
  feedbackGiven = 'feedbackGiven',
  notApplicable = 'notApplicable',
  resolved = 'resolved',
}

export interface IConflictGroup extends IWithId {
  lastConflictSyncAt?: string;
  remarks?: string;
  requestedAdviceAt?: string;
  resolvedAt?: string;
  sgwAdvisingPartyId?: number;
  sgwRequestId: number;
  state?: ConflictGroupState;
  conflictCategory: ConflictCategory;
  isInterRequestConflict: boolean;
  isInfrastructureConflict: boolean;
  isCreatedManually: boolean;
  conflicts: IConflict[];
  isFlagged: boolean;
}
export interface IConflictGroupWithPhaseIds extends IConflictGroup {
  phaseIds: number[];
}
